// 业务管理模块
import store from '@/store'
import http from '@/utils/http'
import LegacyIssuesRectification
  from "V/businessManagement/reportForms/undertakeInspection/legacyIssuesRectification/index.vue";

let preApi = '';
if(store.state.mock && process.env.NODE_ENV == 'development') {
  preApi = '/mock';
}

//添加项目建设规模台账信息
export function addLedgerProjectConstruction(params) {
  return http({
    url: `${preApi}/business/addLedgerProjectConstruction`,
    method: "post",
    data: params
  })
}
//删除项目建设规模台账信息
export function deleteLedgerProjectConstruction(params) {
  return http({
    url: `${preApi}/business/deleteLedgerProjectConstruction`,
    method: "post",
    data: params
  })
}
//修改项目建设规模台账信息
export function modifyLedgerProjectConstruction(params) {
  return http({
    url: `${preApi}/business/modifyLedgerProjectConstruction`,
    method: "post",
    data: params,
  })
}
//根据条件获取项目建设规模台账信息列表
export function getLedgerProjectConstructionByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerProjectConstructionByCondition`,
    method: "post",
    data: params,
  })
}

//添加绿化台账信息
export function addLedgerAfforest(params) {
  return http({
    url: `${preApi}/business/addLedgerAfforest`,
    method: "post",
    data: params
  })
}
//删除绿化台账信息
export function deleteLedgerAfforest(params) {
  return http({
    url: `${preApi}/business/deleteLedgerAfforest`,
    method: "post",
    data: params
  })
}
//修改绿化台账信息
export function modifyLedgerAfforest(params) {
  return http({
    url: `${preApi}/business/modifyLedgerAfforest`,
    method: "post",
    data: params,
  })
}
//根据条件获取绿化台账信息列表
export function getLedgerAfforestByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerAfforestByCondition`,
    method: "post",
    data: params,
  })
}

//添加清洁台账信息
export function addLedgerClean(params) {
  return http({
    url: `${preApi}/business/addLedgerClean`,
    method: "post",
    data: params
  })
}
//删除清洁台账信息
export function deleteLedgerClean(params) {
  return http({
    url: `${preApi}/business/deleteLedgerClean`,
    method: "post",
    data: params
  })
}
//修改清洁台账信息
export function modifyLedgerClean(params) {
  return http({
    url: `${preApi}/business/modifyLedgerClean`,
    method: "post",
    data: params,
  })
}
//根据条件获取清洁台账信息列表
export function getLedgerCleanByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerCleanByCondition`,
    method: "post",
    data: params,
  })
}

//添加消杀台账信息
export function addLedgerDisinfection(params) {
  return http({
    url: `${preApi}/business/addLedgerDisinfection`,
    method: "post",
    data: params
  })
}
//删除消杀台账信息
export function deleteLedgerDisinfection(params) {
  return http({
    url: `${preApi}/business/deleteLedgerDisinfection`,
    method: "post",
    data: params
  })
}
//修改消杀台账信息
export function modifyLedgerDisinfection(params) {
  return http({
    url: `${preApi}/business/modifyLedgerDisinfection`,
    method: "post",
    data: params,
  })
}
//根据条件获取消杀台账信息列表
export function getLedgerDisinfectionByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerDisinfectionByCondition`,
    method: "post",
    data: params,
  })
}

//添加项目标识台账信息
export function addLedgerItemIdentification(params) {
  return http({
    url: `${preApi}/business/addLedgerItemIdentification`,
    method: "post",
    data: params
  })
}
//删除项目标识台账信息
export function deleteLedgerItemIdentification(params) {
  return http({
    url: `${preApi}/business/deleteLedgerItemIdentification`,
    method: "post",
    data: params
  })
}
//修改项目标识台账信息
export function modifyLedgerItemIdentification(params) {
  return http({
    url: `${preApi}/business/modifyLedgerItemIdentification`,
    method: "post",
    data: params,
  })
}
//根据条件获取项目标识台账信息列表
export function getLedgerItemIdentificationByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerItemIdentificationByCondition`,
    method: "post",
    data: params,
  })
}

//添加管理标识台账信息
export function addLedgerManageIdentification(params) {
  return http({
    url: `${preApi}/business/addLedgerManageIdentification`,
    method: "post",
    data: params
  })
}
//删除管理标识台账信息
export function deleteLedgerManageIdentification(params) {
  return http({
    url: `${preApi}/business/deleteLedgerManageIdentification`,
    method: "post",
    data: params
  })
}
//修改管理标识台账信息
export function modifyLedgerManageIdentification(params) {
  return http({
    url: `${preApi}/business/modifyLedgerManageIdentification`,
    method: "post",
    data: params,
  })
}
//根据条件获取管理标识台账信息列表
export function getLedgerManageIdentificationByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerManageIdentificationByCondition`,
    method: "post",
    data: params,
  })
}

//添加管理费台账信息
export function addLedgerManagementCost(params) {
  return http({
    url: `${preApi}/business/addLedgerManagementCost`,
    method: "post",
    data: params
  })
}
//删除管理费台账信息
export function deleteLedgerManagementCost(params) {
  return http({
    url: `${preApi}/business/deleteLedgerManagementCost`,
    method: "post",
    data: params
  })
}
//修改管理费台账信息
export function modifyLedgerManagementCost(params) {
  return http({
    url: `${preApi}/business/modifyLedgerManagementCost`,
    method: "post",
    data: params,
  })
}
//根据条件获取管理费台账信息列表
export function getLedgerManagementCostByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerManagementCostByCondition`,
    method: "post",
    data: params,
  })
}

//添加停车场台账信息
export function addLedgerParking(params) {
  return http({
    url: `${preApi}/business/addLedgerParking`,
    method: "post",
    data: params
  })
}
//删除停车场台账信息
export function deleteLedgerParking(params) {
  return http({
    url: `${preApi}/business/deleteLedgerParking`,
    method: "post",
    data: params
  })
}
//修改停车场台账信息
export function modifyLedgerParking(params) {
  return http({
    url: `${preApi}/business/modifyLedgerParking`,
    method: "post",
    data: params,
  })
}
//根据条件获取停车场台账信息列表
export function getLedgerParkingByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerParkingByCondition`,
    method: "post",
    data: params,
  })
}

//添加风险台账信息
export function addLedgerRisk(params) {
  return http({
    url: `${preApi}/business/addLedgerRisk`,
    method: "post",
    data: params
  })
}
//删除风险台账信息
export function deleteLedgerRisk(params) {
  return http({
    url: `${preApi}/business/deleteLedgerRisk`,
    method: "post",
    data: params
  })
}
//修改风险台账信息
export function modifyLedgerRisk(params) {
  return http({
    url: `${preApi}/business/modifyLedgerRisk`,
    method: "post",
    data: params,
  })
}
//根据条件获取风险台账信息列表
export function getLedgerRiskByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerRiskByCondition`,
    method: "post",
    data: params,
  })
}

//添加白蚁防治台账信息
export function addLedgerTermiteControl(params) {
  return http({
    url: `${preApi}/business/addLedgerTermiteControl`,
    method: "post",
    data: params
  })
}
//删除白蚁防治台账信息
export function deleteLedgerTermiteControl(params) {
  return http({
    url: `${preApi}/business/deleteLedgerTermiteControl`,
    method: "post",
    data: params
  })
}
//修改白蚁防治台账信息
export function modifyLedgerTermiteControl(params) {
  return http({
    url: `${preApi}/business/modifyLedgerTermiteControl`,
    method: "post",
    data: params,
  })
}
//根据条件获取白蚁防治台账信息列表
export function getLedgerTermiteControlByCondition(params) {
  return http({
    url: `${preApi}/business/getLedgerTermiteControlByCondition`,
    method: "post",
    data: params,
  })
}


//添加作业单位任务信息
export function addWorkUnits(params) {
  return http({
    url: `${preApi}/business/addWorkUnits`,
    method: "post",
    data: params
  })
}
//删除作业单位任务信息
export function deleteWorkUnits(params) {
  return http({
    url: `${preApi}/business/deleteWorkUnits`,
    method: "post",
    data: params
  })
}
//修改作业单位任务信息
export function modifyWorkUnits(params) {
  return http({
    url: `${preApi}/business/modifyWorkUnits`,
    method: "post",
    data: params,
  })
}
//根据条件获取作业单位任务信息列表
export function getWorkUnitsListByCondition(params) {
  return http({
    url: `${preApi}/business/getWorkUnitsListByCondition`,
    method: "post",
    data: params,
  })
}
//根据条件获取作业单位任务列表
export function getWorkUnitsList(params) {
  return http({
    url: `${preApi}/business/getWorkUnitsList`,
    method: "post",
    data: params,
  })
}
//导出设施设备excel
export function exportWorkUnitsExcel(params) {
  return http({
    url: `${preApi}/business/exportWorkUnitsExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//添加作业计划信息
export function addWorkPlan(params) {
  return http({
    url: `${preApi}/business/addWorkPlan`,
    method: "post",
    data: params
  })
}
//删除作业计划信息
export function deleteWorkPlan(params) {
  return http({
    url: `${preApi}/business/deleteWorkPlan`,
    method: "post",
    data: params
  })
}
//修改作业计划信息
export function modifyWorkPlan(params) {
  return http({
    url: `${preApi}/business/modifyWorkPlan`,
    method: "post",
    data: params,
  })
}
//根据条件获取作业计划列表
export function getWorkPlanListByCondition(params) {
  return http({
    url: `${preApi}/business/getWorkPlanListByCondition`,
    method: "post",
    data: params,
  })
}
//导出作业计划excel
export function exportWorkPlanExcel(params) {
  return http({
    url: `${preApi}/business/exportWorkPlanExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//批量生成年度保养计划
export function generateYearPlan(params) {
  return http({
    url: `${preApi}/business/generateYearPlan`,
    method: "post",
    data: params,
  })
}

//添加作业任务信息
export function addWorkOrder(params) {
  return http({
    url: `${preApi}/business/addWorkOrder`,
    method: "post",
    data: params
  })
}
//删除作业任务信息
export function deleteWorkOrder(params) {
  return http({
    url: `${preApi}/business/deleteWorkOrder`,
    method: "post",
    data: params
  })
}
//修改作业任务信息
export function modifyWorkOrder(params) {
  return http({
    url: `${preApi}/business/modifyWorkOrder`,
    method: "post",
    data: params,
  })
}
//根据条件获取作业任务列表
export function getWorkOrderListByCondition(params) {
  return http({
    url: `${preApi}/business/getWorkOrderListByCondition`,
    method: "post",
    data: params,
  })
}
// 批量导出作业任务报表
export function exportWorkOrderExcel(params) {
  return http({
    url: `${preApi}/business/exportWorkOrderExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
// 查询工单详情
export function getWorkOrderInfoByOrderId(params) {
  return http({
    url: `${preApi}/business/getWorkOrderInfoByOrderId`,
    method: "post",
    data: params
  })
}
// 查询工单图片
export function getWorkOrderPicByOrderId(params) {
  return http({
    url: `${preApi}/business/getWorkOrderPicByOrderId`,
    method: "post",
    data: params
  })
}

//获取作业人员列表
export function getWorkUsernameList(params) {
  return http({
    url: `${preApi}/business/getWorkUsernameList`,
    method: "post",
    data: params
  })
}

//根据条件获取垃圾处理列表
export function getGarbageClearRecordList(params) {
  return http({
    url: `${preApi}/business/getGarbageClearRecordList`,
    method: "post",
    data: params,
  })
}

//根据条件获取垃圾处理统计信息
export function getGarbageClearStatisticsList(params) {
  return http({
    url: `${preApi}/business/getGarbageClearStatisticsList`,
    method: "post",
    data: params,
  })
}

export function exportGarbageClearStatisticsExcel(params) {
  return http({
    url: `${preApi}/business/exportGarbageClearStatisticsExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//根据条件查询环境管理分析报表信息
export function getEnvironmentAnalysisList(params) {
  return http({
    url: `${preApi}/business/getEnvironmentAnalysisList`,
    method: "post",
    data: params,
  })
}


//添加特别事件报告信息
export function addSpecialEvent(params) {
  return http({
    url: `${preApi}/business/addSpecialEvent`,
    method: "post",
    data: params
  })
}
//获取特别事件编号
export function getSpecialEventNums(params) {
  return http({
    url: `${preApi}/business/getSpecialEventNums`,
    method: "post",
    data: params
  })
}
//删除特别事件报告信息
export function deleteSpecialEvent(params) {
  return http({
    url: `${preApi}/business/deleteSpecialEvent`,
    method: "post",
    data: params
  })
}
//修改特别事件报告信息
export function modifySpecialEvent(params) {
  return http({
    url: `${preApi}/business/modifySpecialEvent`,
    method: "post",
    data: params,
  })
}
//根据条件获取特别事件报告列表
export function getSpecialEventListByCondition(params) {
  return http({
    url: `${preApi}/business/getSpecialEventListByCondition`,
    method: "post",
    data: params,
  })
}

export function exportSpecialEventListExcel(params) {
  return http({
    url: `${preApi}/business/exportSpecialEventListExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
export function exportSpecialEventWord(params) {
  params.filename = '特别事件报告.docx';
  return http({
    url: `${preApi}/business/exportSpecialEventWord`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

export function addWorkPoint(params) {
  return http({
    url: `${preApi}/business/addWorkPoint`,
    method: "post",
    data: params
  })
}
export function modifyWorkPoint(params) {
  return http({
    url: `${preApi}/business/modifyWorkPoint`,
    method: "post",
    data: params
  })
}
export function deleteWorkPoint(params) {
  return http({
    url: `${preApi}/business/deleteWorkPoint`,
    method: "post",
    data: params
  })
}
export function getWorkPointListByCondition(params) {
  return http({
    url: `${preApi}/business/getWorkPointListByCondition`,
    method: "post",
    data: params
  })
}
export function getWorkPointInfoById(params) {
  return http({
    url: `${preApi}/business/getWorkPointInfoById`,
    method: "post",
    data: params
  })
}
//导出作业点信息excel
export function exportWorkPointExcel(params) {
  return http({
    url: `${preApi}/business/exportWorkPointExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}
//导出作业点信息excel
export function exportWorkPointIDCardWord(params) {
  params.filename = '作业点标识卡.docx';
  return http({
    url: `${preApi}/business/exportWorkPointIDCardWord`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}


//添加收入或者支出信息
export function addOperatingStatement(params) {
  return http({
    url: `${preApi}/business/addOperatingStatement`,
    method: "post",
    data: params
  })
}
//删除收入或者支出信息
export function deleteOperatingStatement(params) {
  return http({
    url: `${preApi}/business/deleteOperatingStatement`,
    method: "post",
    data: params
  })
}
//修改收入或者支出信息
export function modifyOperatingStatement(params) {
  return http({
    url: `${preApi}/business/modifyOperatingStatement`,
    method: "post",
    data: params,
  })
}
//确认收入或者支出信息
export function confirmOperatingStatement(params) {
  return http({
    url: `${preApi}/business/confirmOperatingStatement`,
    method: "post",
    data: params,
  })
}
//批量生成年度保养计划
export function generateOperationYearPlan(params) {
  return http({
    url: `${preApi}/business/generateOperationYearPlan`,
    method: "post",
    data: params,
  })
}
//根据条件获取收入或者支出列表
export function getOperatingStatementListByCondition(params) {
  return http({
    url: `${preApi}/business/getOperatingStatementListByCondition`,
    method: "post",
    data: params,
  })
}
//根据条件获取收入或者支出图表数据
export function getOperatingStatementChartsData(params) {
  return http({
    url: `${preApi}/business/getOperatingStatementChartsData`,
    method: "post",
    data: params,
  })
}

//承接查验-增加承接查验报告
export function addUndertakeInspectionReport(params) {
  return http({
    url: `${preApi}/business/addUndertakeInspectionReport`,
    method: "post",
    data: params,
  })
}
//承接查验-获取承接查验报告
export function getUndertakeInspectionReportListByCondition(params) {
  return http({
    url: `${preApi}/business/getUndertakeInspectionReportListByCondition`,
    method: "post",
    data: params,
  })
}
//承接查验-作废承接查验报告
export function voidUndertakeInspectionReport(params) {
  return http({
    url: `${preApi}/business/voidUndertakeInspectionReport`,
    method: "post",
    data: params,
  })
}

//承接查验-导出承接查验报告
export function exportUndertakeInspectionReportExcel(params) {
  return http({
    url: `${preApi}/business/exportUndertakeInspectionReportExcel`,
    method: "post",
    data: params,
    responseType: 'blob'
  })
}

//承接查验-增加整改跟进
export function addLegacyIssuesRectification(params) {
  return http({
    url: `${preApi}/business/addLegacyIssuesRectification`,
    method: "post",
    data: params,
  })
}

//承接查验-修改整改跟进
export function editLegacyIssuesRectification(params) {
  return http({
    url: `${preApi}/business/editLegacyIssuesRectification`,
    method: "post",
    data: params,
  })
}
//承接查验-获取整改跟进
export function getLegacyIssuesRectificationListByCondition(params) {
  return http({
    url: `${preApi}/business/getLegacyIssuesRectificationListByCondition`,
    method: "post",
    data: params,
  })
}
//承接查验-作废整改跟进
export function voidLegacyIssuesRectification(params) {
  return http({
    url: `${preApi}/business/voidLegacyIssuesRectification`,
    method: "post",
    data: params,
  })
}

//承接查验-导出整改跟进
export function exportLegacyIssuesRectificationExcel(params) {
  return http({
    url: `${preApi}/business/exportLegacyIssuesRectificationExcel`,
    method: "post",
    data: params,
      responseType: 'blob'
  })
}