<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1350" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{ showType == 'detail' ? '关闭' : '取消' }}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">
          提交
        </a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="currentRules"
                    :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="userdep_id">
          <a-select :disabled="showType==='detail'" v-model="formData.userdep_id" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.userdepid">
              {{ item.monitorpointname }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设备类型" prop="device_type">
<!--          <a-input :disabled="showType==='detail'" v-model.trim="formData.device_type"/>-->
          <a-select v-model="formData.device_type" :disabled="showType==='detail'">
            <a-select-option v-for="(item, index) in deviceTypeList" :key="index" :value="item.name">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="设施设备名称" prop="device_name">
          <a-input :disabled="showType==='detail'" v-model.trim="formData.device_name"/>
        </a-form-model-item>
        <a-form-model-item label="设施设备编号" prop="device_code">
          <a-input :disabled="showType==='detail'" v-model.trim="formData.device_code"/>
        </a-form-model-item>
        <a-form-model-item label="问题简述(最多1000个字符)" prop="overview">
          <a-textarea :disabled="showType==='detail'" v-model.trim="formData.overview" :maxLength="1000"/>
          <div style="position: relative;font-size: 13px;text-align: right;margin-top: -26px;margin-right: 10px;">{{formData.overview.length}}/1000</div>
        </a-form-model-item>
        <a-form-model-item label="整改单位" prop="rectification_organization">
          <a-input :disabled="showType==='detail'" v-model.trim="formData.rectification_organization"/>
        </a-form-model-item>
        <a-form-model-item label="计划完成时间" prop="planned_completion_date">
          <a-date-picker  :disabled="showType==='detail'" v-model.trim="formData.planned_completion_date" />
        </a-form-model-item>
        <a-form-model-item label="实际完成时间" prop="actual_completion_date">
          <a-date-picker  :disabled="showType==='detail'" v-model.trim="formData.actual_completion_date" />
        </a-form-model-item>
        <a-form-model-item label="完成状态" prop="completion_status">
          <a-select :disabled="showType==='detail'" v-model="formData.completion_status">
            <a-select-option value="0">完成</a-select-option>
            <a-select-option value="1">未完成</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="showType==='detail'" label="是否作废" prop="cancel" >
          <a-select :disabled="showType==='detail'" v-model="formData.cancel">
            <a-select-option value="0">正常</a-select-option>
            <a-select-option value="1">作废</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="查验人员" prop="inspection_person">
          <a-input :disabled="showType==='detail'" v-model.trim="formData.inspection_person"/>
        </a-form-model-item>
        <a-form-model-item label="说明(最多1000个字符)" prop="remark" >
          <a-textarea :disabled="showType==='detail'" v-model.trim="formData.remark" :maxLength="1000"/>
          <div style="position: relative;font-size: 13px;text-align: right;margin-top: -26px;margin-right: 10px;">{{formData.remark.length}}/1000</div>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {addLegacyIssuesRectification,editLegacyIssuesRectification} from "A/businessmanagement";
import {getDictionaryTypeListByCondition} from "A/xtpz";
import {editEcAllocationRecord} from "A/energyconsumption";


export default {
  mixins: [areaselect, deptselect, ],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    },
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '350px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        userdep_id: '',
        device_type:'',
        device_name:"",
        device_code:'',
        overview:"",
        rectification_organization:'',
        planned_completion_date:"",
        actual_completion_date:'',
        inspection_person:'',
        completion_status:"1",
        cancel:"0",
        remark:''
      },
      //这里面的数据属于必填项
      formRules: {
        userdep_id: [{required: true, message: '请选择项目'}],
        device_type: [{required: true, message: '请选择设备类型'}],
        device_name: [{required: true, message: '请输入设施设备名称'}],
        device_code: [{required: true, message: '请输入设施设备编号'}],
        overview: [{required: true, message: '请输入问题简述(最多1000个字符)'}],
        rectification_organization: [{required: true, message: '请输入整改单位'}],
        planned_completion_date: [{required: true, message: '请选择计划完成时间'}],
        //actual_completion_date: [{required: true, message: '请选择实际完成时间'}],
        inspection_person: [{required: true, message: '请输入查验人员'}],
        completion_status: [{required: true, message: '请选择完成状态'}],
        void: [{required: true, message: ''}],
        //remark: [{required: true, message: '请输入说明(最多1000个字符)'}],
      },
      formRules2: {
        actual_completion_date: [{required: true, message: '请选择实际完成时间'}],
      },

      ecPositionAllList: [],
      monitorpointList: [],
      ecCategoryAllList:[],

      ecMeterFormAllList: [],
      ecGradeList: [],
      deviceTypeList:[],
      //actual_completion_date:[{required: true, message: '请选择实际完成时间'}]
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if (this.showType == 'add') {
        return '新增'
      } else if (this.showType == 'edit') {
        return '修改'
      } else if (this.showType == 'detail') {
        return '详情'
      } else {
        return '';
      }
    },
    currentRules(){
      if(this.formData.completion_status==='1'){
        return this.formRules
      }else{
        return {...this.formRules, ...this.formRules2}
      }
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.getFatherDeviceTypeList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if (!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
    'formData.userdep_id'(val) {
      //this.formData.monitorpointnum = ''
      for (let item of this.monitorpointList) {
        if (item.userdepid === val) {
          this.formData.monitorpointnum = item.monitorpointnum
        }
      }
    },
    'formData.completion_status'(val){
      if(val&& val.length!==0){

      }
    }
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    onChange(option) {
      this.formData.host_num = option.replace(/\s*/g, "")
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    getFatherDeviceTypeList(){
      let params = {
        //这个要对照数据库
        code: '66,67,68,69,70,71,72,73,74'
      };
      getDictionaryTypeListByCondition(params).then(res => {
        if (res && res.returncode === '0') {
          //console.log(res.item)
          this.deviceTypeList = res.item ? res.item : []
        }
      })
    },
    getMonitorPointNameList() {
      getMonitorPointNameListByCondition({userdepsid: this.userInfo.userdepid}).then(res => {
        if (res && res.returncode == '0') {
          this.monitorpointList = res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.formData.userdep_id = ''
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.id) {
        if (this.showType == 'edit' || this.showType == 'detail') {

          this.formData.id = this.detailData.id
          this.formData.device_type = this.detailData.device_type
          this.formData.device_name = this.detailData.device_name
          this.formData.device_code = this.detailData.device_code
          this.formData.overview = this.detailData.overview
          this.formData.rectification_organization = this.detailData.rectification_organization
          this.formData.planned_completion_date = this.detailData.planned_completion_date
          this.formData.actual_completion_date = this.detailData.actual_completion_date
          this.formData.inspection_person = this.detailData.inspection_person
          this.formData.completion_status = this.detailData.completion_status+''
          this.formData.cancel = this.detailData.void+''
          this.formData.remark = this.detailData.remark

          this.formData.userdep_id = this.detailData.userdep_id
          this.formData.monitorpointnum = this.detailData.monitorpointnum

        } else {
          this.resetData()
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.planned_completion_date=moment(this.formData.planned_completion_date).format("YYYY-MM-DD")
            this.formData.actual_completion_date=this.formData.actual_completion_date?moment(this.formData.actual_completion_date).format("YYYY-MM-DD"):null
            let params = {
              ...this.formData,
              //userdepsid:this.formData.userdep_id
            };
            if (this.showType == 'add') {
              this.showLoading();
              params.cancel='0'
              addLegacyIssuesRectification(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if (this.showType == 'edit') {
              this.showLoading();
              editLegacyIssuesRectification(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else {
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    selectAllocationRecord(){
      this.selectVisible=true
    },
    getPrimaryRecordList(rows){
      this.formData.code=rows[0].code;
      this.formData.type=rows[0].type;
      this.formData.level=rows[0].level;
      this.formData.meter_name=rows[0].meter_name;
      this.formData.meter_code=rows[0].meter_code;
      this.formData.record_id=rows[0].id;

      this.selectedRecord=rows
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>