<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }"
                          :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')"
                          v-model="userdepidCascaderSelected"
                          :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll"
                          placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="设备类型" prop="device_type" style="width: 25%">
<!--              <a-input v-model="queryParams.device_type"></a-input>-->
              <a-select v-model="queryParams.device_type">
                <a-select-option value="">全部</a-select-option>
                <a-select-option v-for="(item, index) in deviceTypeList" :key="index" :value="item.name">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="设施设备名称" prop="device_name" style="width: 25%">
              <a-input v-model="queryParams.device_name"></a-input>
            </a-form-model-item>

            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开
                <a-icon :type="showAdvanced?'up':'down'"></a-icon>
              </a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="设施设备编号" prop="device_code" style="width: 25%">
                <a-input v-model="queryParams.device_code"></a-input>
              </a-form-model-item>
              <a-form-model-item label="整改单位" prop="rectification_organization" style="width: 25%">
                <a-input v-model="queryParams.rectification_organization"></a-input>
              </a-form-model-item>
              <a-form-model-item label="完成状态" prop="completion_status" style="width: 25%">
                <a-select v-model="queryParams.completion_status">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">完成</a-select-option>
                  <a-select-option value="1">未完成</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="是否作废" prop="void" style="width: 25%">
                <a-select v-model="queryParams.void">
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="0">正常</a-select-option>
                  <a-select-option value="1">作废</a-select-option>
                </a-select>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">遗留问题整改跟进</h3>
          <div class="table-btns">
            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>
            <a-button type="primary" @click="showModal('add')">
              <a-icon type="plus"></a-icon>
              新建
            </a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="id"
                 :loading="tableLoading"
                 :scroll="{ x: 2000}"
                 :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')"
                 style="margin-top: 20px;" :pagination="false">
              <span slot="create_time" slot-scope="value, record" >
             {{moment(value).format("YYYY-MM-DD HH:mm:ss")}}
            </span>
            <span slot="void" slot-scope="value, record" >
               {{value===0?'正常':'作废'}}
            </span>
          <span slot="completion_status" slot-scope="value, record" >
               {{value===0?'完成':'未完成'}}
            </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.id">详情</a-menu-item>
                <a-menu-item v-if="record.completion_status===1" :key="'edit-'+record.id">修改</a-menu-item>
                <a-menu-item v-if="record.void===0" :key="'void-'+record.id">作废</a-menu-item>
                <!--                <a-menu-item :key="'delete-'+record.id">删除</a-menu-item>-->
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer
                      @change="pageChange" @showSizeChange="onShowSizeChange"
                      :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData"  @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import {
  getLegacyIssuesRectificationListByCondition,
  voidLegacyIssuesRectification,
  exportLegacyIssuesRectificationExcel
} from "A/businessmanagement";
import {
  getDictionaryTypeListByCondition,
} from "A/xtpz";

import addOrEditModal from "./addOrEditModal";
import {exportEcMeterRecordExcel} from "A/energyconsumption";

export default {
  name: "undertakerInspectionReport",
  mixins: [deptselect, pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      ecGroupList:[],
      queryParams: {
        device_type:'',
        void:'',
      device_name:"",
        device_code:"",
        rectification_organization:"",
        completion_status:"",

      },
      tableColumns: [
        {
          title: '项目名称',
          dataIndex: 'userdepname',
          key: 'userdepname',
          ellipsis: true,
        },
        {
          title: '设备类型',
          dataIndex: 'device_type',
          key: 'device_type',
          ellipsis: true,
        },
        {
          title: '设施设备名称',
          dataIndex: 'device_name',
          key: 'device_name',
          ellipsis: true,
        },
        {
          title: '设施设备编号',
          dataIndex: 'device_code',
          key: 'device_code',
          ellipsis: true,
        },
        {
          title: '问题简述',
          dataIndex: 'overview',
          key: 'overview',
          ellipsis: true,
        },
        {
          title: '整改单位',
          dataIndex: 'rectification_organization',
          key: 'rectification_organization',
          ellipsis: true,
        },
        {
          title: '计划完成时间',
          dataIndex: 'planned_completion_date',
          key: 'planned_completion_date',
          ellipsis: true,
        },
        {
          title: '实际完成时间',
          dataIndex: 'actual_completion_date',
          key: 'actual_completion_date',
          ellipsis: true,
        },
        {
          title: '完成状态',
          dataIndex: 'completion_status',
          key: 'completion_status',
          ellipsis: true,
          scopedSlots: { customRender: 'completion_status' }
        },
        {
          title: '是否作废',
          dataIndex: 'void',
          key: 'void',
          ellipsis: true,
          scopedSlots: { customRender: 'void' }
        },
        {
          title: '查验人员',
          dataIndex: 'inspection_person',
          key: 'inspection_person',
          ellipsis: true,
        },
        {
          title: '录入时间',
          dataIndex: 'create_time',
          key: 'create_time',
          ellipsis: true,
          scopedSlots: { customRender: 'create_time' }
        },
        {
          title: '录入人员',
          dataIndex: 'username',
          key: 'username',
          ellipsis: true,
        },
        {
          title: '录入账号',
          dataIndex: 'useraccount',
          key: 'useraccount',
          ellipsis: true,
        },

        {
          title: '说明',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align: 'center',
          scopedSlots: {customRender: 'operation'},
          width: 70
        },
      ],
      ecPositionAllList: [],
      ecCategoryAllList: [],
      ecRecordAllIdAndCodeList: [],


      copyData: {},
      userdepidCascaderSelected: [],
      deviceCodeList: [],
      equipmentStatusList: [],
      equipmentCategoryMap: {},
      equipmentCategoryList: [],
      equipmentChildCategoryList: [],
      equipmentChildCategoryMap: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      //操作按钮权限
      btnList: [],
      menu: {},
      deviceTypeList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "遗留问题整改跟进"
    },
    breadcrumb() {
      const pages = [{name: "业务管理", path: ""}]
      pages.push({name: "报告/报表管理", path: ""})
      pages.push({name: "承接查验", path: ""})
      pages.push({name: this.pageTitle, path: ""})
      return pages
    },
  },
  watch: {
    userdepidCascaderSelected(val) {
      if (val && val.length) {
        this.queryParams.userdepsid = val[val.length - 1];
      } else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu = getItemFromArrayByKey(this.operationMenuTree, "menuid", this.$route.params.menuid, "children")
    for (let i = 0; i < this.menu.children.length; i++) {//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.init();
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods: {
    init(){
      this.getFatherDeviceTypeList()
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLegacyIssuesRectificationListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    //获取作业子类别
    getFatherDeviceTypeList(){
      let params = {
        //这个要对照数据库
        code: '66,67,68,69,70,71,72,73,74'
      };
      getDictionaryTypeListByCondition(params).then(res => {
        if (res && res.returncode === '0') {
          //console.log(res.item)
          this.deviceTypeList = res.item ? res.item : []
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = []
      this.$refs.queryForm.resetFields();
    },

    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'id', id);
      if (type == 'void') {
        this.voidConfirm(id, record)
      } else {
        this.showModal(type, record)
      }
    },
    showModal(type, record) {
      if(type === 'add'){
        this.modalVisible=true
        this.modalShowType=type
      } else if (type === 'detail') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      } else if (type === 'edit') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalVisible = true
      }
    },
    voidConfirm(value, record) {
      this.$confirm('确定要作废该数据?', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.cancel(record.id);
      }).catch(() => {

      });
    },
    cancel(id) {
      this.showLoading();
      if (id) {
        let params = {
          id,
          cancel:'1'
        };
        voidLegacyIssuesRectification(params).then(res => {
          this.hideLoading();
          if (res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          } else {
            this.$message.error(res.errormsg || '操作失败');
          }
        })
      }
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index) {
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData = record
            this.modalShowType = "detail"
            this.modalVisible = true
          },
        },
      }
    },
    exportModal() {
      this.$confirm('你确认要按照所选筛选条件导出数据吗？', {
        type: 'warning',
        centered: true,
      }).then(() => {
        this.exportLegacyIssuesRectificationRecord()
      }).catch(() => {
      });
    },
    exportLegacyIssuesRectificationRecord() {
      let params = {
        ...this.queryParams,
      }
      //只导出未作废的记录
      params.void='0'
      this.showLoading();
      exportLegacyIssuesRectificationExcel(params).then((res) => {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(() => {
        this.hideLoading();
      })
    },
  },
}
</script>
<style scoped>

</style>